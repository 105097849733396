/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { findBySlug, renderLineBreak } from "../helpers"
import get from "lodash/get"

import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import BenefitsModule from "../components/benefits-module"
import ProjectGalleryModule from "../components/project-gallery-module"
import AdvantagesModule from "../components/advantages-module"
import TestimonialsModule from "../components/testimonials-module"
import ProcessModule from "../components/process-module"
import Quote from "../components/design-partner/quote"

import React from "react"
import AwardsModule from "../components/awards-module"
import Container from "../components/container"
import Column from "../components/column"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"
import BiosModal from "../components/bios-modal"
import ViewMoreIcon from "../images/viewmore.png"

import { paramsCheck } from "../functions/functions"
import { object } from "prop-types"
import { useEffect } from "react"

const PlantDesign = ({ data, location }) => {
  paramsCheck();
  
  let hashVal = location.hash.split("?")

  const [showBiosModal, setShowBiosModal] = React.useState(false)
  const [biosData, setBiosData] = React.useState(null)
  const setBiosModal = data => {
    data && data.bios === null
      ? setShowBiosModal(false)
      : setShowBiosModal(true)
    setBiosData(data)
  }

  useEffect(() => {
    if (location.hash && hashVal[0] && typeof window !== null) {
      let id = hashVal[0].substring(1)
      const elem = document.getElementById(id)
      if (elem) {
        window.scrollTo({ left: 0, top: elem.offsetTop + 30 })
      }
    }
  }, [])

  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    const ctaList = [
      {
        text: heroBladePatternData.ctaTitle,
        type: "secondary",
        link: `/${heroBladePatternData.ctaDestination}`,
      },
    ]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        body={heroBladePatternData.description.description}
        title={heroBladePatternData.title}
        ctaList={ctaList}
        type="secondary"
        bodyStyle={{fontSize:17}}
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-description-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-process-module",
    })

    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-testimonial-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderAdvantagesModule = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-advantages-module",
    })
    if (!advantagesBladePatternData) return

    return <AdvantagesModule bladePatternData={advantagesBladePatternData} />
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderDraftProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-draft-project-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        // projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", height: 0 }}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-completed-project-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        // projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", height: 0 }}
        isSwipable={true}
      />
    )
  }
  const renderDesignProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-design-collaborations",
    })
    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        customStyle={{ pb: "61%", height: 0 }}
        isSwipable={true}
        linkPrefix={"/architects"}
      />
    )
  }

  const renderReferenceProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-reference-designs",
    })
    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        customStyle={{ pb: "61%", height: 0 }}
        isSwipable={true}
        linkPrefix={"/models"}
      />
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  const renderAwardsModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-awards-module",
    })
    if (!bladePatternData) return

    return (
      <div
        sx={{
          background: theme => theme.colors.backgrounds.primary,
        }}
      >
        <AwardsModule
          bladePatternData={bladePatternData}
          titleAlignament="center"
          titleTextType="h2"
        />
      </div>
    )
  }

  const renderOurTeamModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "studio-team-module",
    });

    // const bladePatternDataTeamDirector = findBySlug({
    //   list: data.contentfulPage.bladePatternList,
    //   slug: "company-board-of-directors",
    // })

    // const teamGalleryBladePatternData = findBySlug({
    //   list: data.contentfulPage.bladePatternList,
    //   slug: "company-team-gallery",
    // })

    // const renderTeamImage = idx => {
    //   const imgUrl = get(
    //     teamGalleryBladePatternData,
    //     `contentCards[${idx}].desktopImage.file.url`,
    //     "/"
    //   )
    //   return <img src={imgUrl} alt="" />
    // }
    // if (!bladePatternData) return
    // if (!bladePatternDataTeamDirector) return
    return bladePatternData?(
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
        }}
      >
        <Container>
          <Row customStyle={{ mb: theme => theme.spacing.vertical.sm }}>
            <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
          </Row>
          {bladePatternData.contentCards ? (
            <Row>
              {bladePatternData.contentCards.length &&
                bladePatternData.contentCards.map((basicCard, idx) => (
                  <Column
                    size={[6, 4, 2]}
                    key={idx}
                    customStyle={{
                      mb: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.md,
                      ],
                      cursor: "pointer",
                    }}
                  >
                    <span onClick={() => setBiosModal(basicCard, null)}>
                      <div sx={{ maxWidth: 200 }}>
                        <img
                          src={get(basicCard, "desktopImage.file.url")}
                          alt=""
                          sx={{
                            mb: theme => theme.spacing.vertical.sm,
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Text type="h4">{basicCard.title}</Text>
                        {basicCard.bios !== null && (
                          <img
                            sx={{
                              marginTop: "-3px",
                              marginLeft: "3px",
                              height: 25,
                              filter:
                                "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                              position: "relative",
                            }}
                            src={ViewMoreIcon}
                            alt=""
                          ></img>
                        )}
                      </div>

                      <RichText data={basicCard.description} textStyle={{fontSize:17}}/>
                    </span>
                  </Column>
                ))}
            </Row>
          ) : null}
        </Container>
        {/* {renderQuote()} */}
        {/* Section for Board of directors */}
        {/* <Container>
          <Row customStyle={{ my: theme => theme.spacing.vertical.sm }}>
            <Text type="h2">
              {renderLineBreak(bladePatternDataTeamDirector.title)}
            </Text>
          </Row>
          {bladePatternDataTeamDirector.contentCards ? (
            <Row>
              {bladePatternDataTeamDirector.contentCards.length &&
                bladePatternDataTeamDirector.contentCards.map(
                  (basicCard, idx) => (
                    <Column
                      size={[6, 4, 2]}
                      key={idx}
                      customStyle={{
                        mb: theme => [
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.sm,
                          theme.spacing.vertical.md,
                        ],
                        cursor: "pointer",
                      }}
                    >
                      <span onClick={() => setBiosModal(basicCard, null)}>
                        <div sx={{ maxWidth: 200 }}>
                          <img
                            src={get(basicCard, "desktopImage.file.url")}
                            alt=""
                            sx={{
                              mb: theme => theme.spacing.vertical.sm,
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <Text type="h4">{basicCard.title}</Text>
                          {basicCard.bios !== null && (
                            <img
                              sx={{
                                marginTop: "-3px",
                                marginLeft: "3px",
                                height: 25,
                                filter:
                                  "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
                                position: "relative",
                              }}
                              src={ViewMoreIcon}
                              alt=""
                            ></img>
                          )}
                        </div>

                        <RichText data={basicCard.description} />
                      </span>
                    </Column>
                  )
                )}
            </Row>
          ) : null}
        </Container> */}
        {/* Section hided until we get content*/}
        {/* <Container customStyle={{ pb: 5 }}>
          <Row>
            <Column
              size={[12, 12, 6]}
              customStyle={{
                mb: theme => [
                  theme.spacing.horizontal,
                  theme.spacing.horizontal,
                  0,
                ],
              }}
            >
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[12]}>{renderTeamImage(0)}</Column>
              </Row>
              <Row>
                <Column size={[6]}>{renderTeamImage(1)}</Column>
                <Column size={[6]}>{renderTeamImage(2)}</Column>
              </Row>
            </Column>
            <Column size={[12, 12, 6]}>
              <Row customStyle={{ mb: theme => theme.spacing.horizontal }}>
                <Column size={[6]}>{renderTeamImage(3)}</Column>
                <Column size={[6]}>{renderTeamImage(4)}</Column>
              </Row>
              <Row>
                <Column size={[12]}>{renderTeamImage(5)}</Column>
              </Row>
            </Column>
          </Row>
        </Container> */}
      </div>
    ):(<></>)
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderServicesModule()}
      {renderOurTeamModule()}
      {renderProcessModule()}
      {renderDraftProjectGalleryModule()}
      {renderProjectGalleryModule()}
      {renderDesignProjectGalleryModule()}
      {renderReferenceProjectGalleryModule()}
      {renderTestimonialsModule()}
      {renderAdvantagesModule()}
      {renderAwardsModule()}
      {renderCtaModule()}
      {renderQuote()}
    </Page>
  )
}

export default PlantDesign

export const query = graphql`
  query plantDesignPageQuery {
    contentfulPage(slug: { eq: "plant-design" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
          projectTytpe
        }
      }
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "homebuyers-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
